import useSignOut from "react-auth-kit/hooks/useSignOut";
import { useTranslation } from "react-i18next";
import { IoIosLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Roles, SUPER_ADMIN, USER } from "../constants/roles";
import { IAuthMe } from '../@types/types'
import { useQueryClient } from '@tanstack/react-query'

const NameBar = ({ showLogOut = true, onClose }: { showLogOut?: boolean; onClose?: () => void }) => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["user"]) as IAuthMe;

  return (
    <div className="max-w-[290px] h-[32px] border-2 border-[#E8E8E8] rounded-full flex items-center justify-between">
      <div className="w-auto h-[32px] flex items-center justify-between px-3">
        {user?.first_name ? (
          <div className="w-full flex items-center cursor-pointer" onClick={() => navigate("/profile")}>
            <span className="font-bold mr-2">{user?.username}</span>
            {user?.role_id !== USER && (
              <div
                className={`w-auto ${
                  user?.role_id === SUPER_ADMIN ? "bg-red-500 text-white" : "bg-yellow-400"
                } px-2 flex items-center justify-center rounded-full`}
              >
                {
                  <span className="text-[11px] font-bold">
                    {user?.role_id === SUPER_ADMIN ? Roles.SUPER_ADMIN : Roles.ADMIN}
                  </span>
                }
              </div>
            )}
          </div>
        ) : (
          t("loading")
        )}
      </div>
      {showLogOut && (
        <div className="border-l-2">
          <IoIosLogOut
            onClick={() => {
              signOut(), navigate("/", { replace: true }), onClose?.();
            }}
            className="w-6 h-6 mx-2 cursor-pointer"
          />
        </div>
      )}
    </div>
  );
};

export default NameBar;
