import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import am from '../public/locales/am.json';
import en from '../public/locales/en.json';
import ru from '../public/locales/ru.json';

i18n.use(initReactI18next).init({
	resources: {
		hy: {
			translation: am,
		},
		en: {
			translation: en,
		},
		ru: {
			translation: ru,
		},
	},
	lng: localStorage.getItem('i18nextLng') || 'en',
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
