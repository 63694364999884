import { Building, ICreateBuilding } from "../@types/types";
import { $api } from "./api-client";

type TBuiling = {
  building: Building;
};

export const getProjectBuildings = async (projectId: number): Promise<Building[]> => {
  return (await $api.get<Building[]>(`/private/buildings/project/${projectId}`)).data;
};

export const geProjectBuiling = async (buildingId: number): Promise<TBuiling> => {
  return (await $api.get<TBuiling>(`/private/buildings/${buildingId}`)).data;
};

export const createBuiling = async (building: ICreateBuilding): Promise<{ success: boolean }> => {
  return (await $api.post<{ success: boolean }>("/private/buildings", building)).data;
};

export const deleteBuilding = async (id: number): Promise<void> => {
  await $api.delete(`/private/buildings/${id}`);
};

export const updateBuilding = async (id: number, building: ICreateBuilding): Promise<ICreateBuilding> => {
  return (await $api.put<ICreateBuilding>(`/private/buildings/${id}`, building)).data;
};

export const updateDegree = async (id: number, degree: number): Promise<{ success: boolean }> => {
  return (
    await $api.put<{ success: boolean }>(`/private/buildings/north-degree`, { id, north_degree: degree })
  ).data;
};

export const copyBuilding = async (id: number): Promise<{id: number}> => {
  return (await $api.post<{id: number}>(`/private/buildings/copy/${id}`)).data;
}