import { ILateBookings, IUpdateLateBooking } from "../@types/types";
import { $api } from "./api-client";

export const getLateBookings = async (id: string): Promise<ILateBookings> => {
  return (await $api.get<ILateBookings>(`/private/late-bookings/project/${id}`)).data;
};

export const updateLateBookings = async (data: IUpdateLateBooking): Promise<{ success: boolean }> => {
  return (await $api.put<{ success: boolean }>(`/private/late-bookings/change-deal-end-time`, data)).data;
};
