import { $api } from "./api-client";

interface Response {
  id: number;
}

export const addImage = async (file: File[]): Promise<Response> => {
  const formData = new FormData();
  formData.append("image", file[0]);

  return (
    await $api.post<Response>("/private/images", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        accept: "application/json",
      },
    })
  ).data;
};

export const getImageById = async (id: number) => {
  return (await $api.get(`/private/images/${id}`)).data;
}