import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";

const Layout = () => {
  return (
    <div>
      <Header />
      <div className="w-full min-h-screen flex items-center justify-center bg-[#F3F4F5]">
        <Suspense
          fallback={
            <div className="flex justify-center items-center w-screen h-screen overflow-hidden">
              <div className="relative inline-flex">
                <div className="w-8 h-8 bg-primary rounded-full"></div>
                <div className="w-8 h-8 bg-primary rounded-full absolute top-0 left-0 animate-ping"></div>
                <div className="w-8 h-8 bg-primary rounded-full absolute top-0 left-0 animate-pulse"></div>
              </div>
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};

export default Layout;
