import { Button, Checkbox, FormControl, FormErrorMessage, Input, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { API } from "../services/api";
import { LoginInputs } from "../services/auth";

type Inputs = {
  username: string;
  password: string;
};

const Login = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const [checked, setChecked] = useState<number>(0);

  const toast = useToast();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const isAuth = useIsAuthenticated();
  const { width } = useWindowDimensions();

  const loginMutation = useMutation({
    mutationFn: (data: LoginInputs) => API.auth.login(data),
    onSuccess: (data) => {
      if (data.token) {
        if (
          signIn({
            auth: {
              token: data.token,
              type: "Bearer",
            },
          })
        ) {
          navigate("/projects", { replace: true });
        }
      }
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: t("wrongCredentials"),
        status: "error",
        position: "top-right",
        duration: 3000,
      });
      console.log(error);
    },
  });

  const onSubmitForm: SubmitHandler<Inputs> = async (data) => {
    const postData: LoginInputs = {
      username: data.username,
      password: data.password,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    loginMutation.mutate(postData);
  };

  useEffect(() => {
    if (isAuth) {
      navigate("/projects", { replace: true });
    }
  }, [isAuth]);

  return (
    <div
      style={{
        backgroundImage: width > 499 ? "url(/images/login-background.jpg)" : "",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="w-full h-screen flex items-center justify-center bg-white"
    >
      <form
        onSubmit={handleSubmit(onSubmitForm)}
        className="w-[448px] h-auto rounded-md bg-white p-[64px] flex flex-col items-center"
      >
        <p className="font-bold text-[32px] text-center max-[380px]:text-[25px]">{t("loginPage.welcome")}</p>
        <FormControl isInvalid={!!errors.username}>
          <Input
            {...register("username", {
              required: t("loginPage.usernameIsRequired"),
              minLength: {
                value: 5,
                message: t("loginPage.inputMinimum5Symbols"),
              },
            })}
            variant="flushed"
            placeholder={t("username")}
            _placeholder={{ fontWeight: "normal" }}
            _focus={{ borderColor: "#0B57D0" }}
            mt={35}
            fontWeight="semibold"
          />
          {errors.username?.message && <FormErrorMessage>{errors.username?.message}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={!!errors.password}>
          <Input
            {...register("password", {
              required: t("loginPage.passwordIsRequired"),
              minLength: {
                value: 6,
                message: t("loginPage.inputMinimum6Symbols"),
              },
            })}
            type="password"
            variant="flushed"
            placeholder={t("loginPage.password")}
            _placeholder={{ fontWeight: "normal" }}
            _focus={{ borderColor: "#0B57D0" }}
            mt={25}
            fontWeight="semibold"
          />
          {errors.password?.message && <FormErrorMessage>{errors.password?.message}</FormErrorMessage>}
        </FormControl>

        <div className="w-full flex items-center justify-start mt-[20px]">
          <Checkbox onChange={() => setChecked(checked === 0 ? 1 : 0)} size="md" value={checked}>
            <span className="text-[#696969] text-sm max-[380px]:text-[13px]">
              {t("loginPage.saveToSystem")}
            </span>
          </Checkbox>
        </div>

        <Button
          type="submit"
          variant="custom"
          mt="40px"
          w="full"
          h="48px"
          isLoading={loginMutation.isPending}
        >
          {t("loginPage.signin")}
        </Button>
      </form>
    </div>
  );
};

export default Login;
