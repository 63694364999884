/* eslint-disable react-refresh/only-export-components */
import RequireAuth from "@auth-kit/react-router/RequireAuth";
import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./layout";
import ErrorBoundary from './pages/ErrorBoundary'
import Login from './pages/Login';

const Apartment = lazy(() => import("./pages/Apartment"));
const Building = lazy(() => import("./pages/Building"));
const ChangePassword = lazy(() => import("./pages/ChangePassword"));
const LateBookings = lazy(() => import("./pages/LateBookings"));
const LateBookingsProjectInfo = lazy(() => import("./pages/LateBookingsProjectInfo"));
const NotFound = lazy(() => import("./pages/NotFound"));
const PrivateBuildings = lazy(() => import("./pages/PrivateBuildings"));
const Profile = lazy(() => import("./pages/Profile"));
const Projects = lazy(() => import("./pages/Projects"));
const ProjectStatistics = lazy(() => import("./pages/ProjectStatistics"));
const Report = lazy(() => import("./pages/Report"));
const Reports = lazy(() => import("./pages/Reports"));
const SoldApartments = lazy(() => import("./pages/SoldApartments"));
const SoldApartmentsInfo = lazy(() => import("./pages/SoldApartmentsInfo"));
const Statistics = lazy(() => import("./pages/Statistics"));
const Users = lazy(() => import("./pages/Users"));

// import Partners from "./pages/Partners";
// import Payed from "./pages/Payed";
// import PendingFees from "./pages/PendingFees";
// import ConfirmEmail from "./pages/ConfirmEmail";
// import ExtendedGraphic from "./pages/ExtendedGraphic";
// import ForgotPassword from "./pages/ForgotPassword";
// import Bank from './pages/Bank';

const urls = [
  { path: "/", element: <Login /> },
  { path: "*", element: <NotFound /> },
  {
    path: "/users",
    element: (
      <RequireAuth fallbackPath="/">
        <Users />
      </RequireAuth>
    ),
  },
  {
    path: "/reports",
    element: (
      <RequireAuth fallbackPath="/">
        <Reports />
      </RequireAuth>
    ),
  },
  {
    path: "/profile",
    element: (
      <RequireAuth fallbackPath="/">
        <Profile />
      </RequireAuth>
    ),
  },
  // {
  //   path: "/partners",
  //   element: (
  //     <RequireAuth fallbackPath="/">
  //       <Partners />
  //     </RequireAuth>
  //   ),
  // },
  {
    path: "/projects",
    element: (
      <RequireAuth fallbackPath="/">
        <Projects />
      </RequireAuth>
    ),
  },
  {
    path: "/statistics",
    element: (
      <RequireAuth fallbackPath="/">
        <Statistics />
      </RequireAuth>
    ),
  },
  {
    path: "/reports/uuid/:report",
    element: (
      <RequireAuth fallbackPath="/">
        <Report />
      </RequireAuth>
    ),
  },
  // { path: "/confirm-email", element: <ConfirmEmail /> },
  {
    path: "/late-bookings",
    element: (
      <RequireAuth fallbackPath="/">
        <LateBookings />
      </RequireAuth>
    ),
  },
  // {
  //   path: "/sold-apartments/payed",
  //   element: (
  //     <RequireAuth fallbackPath="/">
  //       <Payed />
  //     </RequireAuth>
  //   ),
  // },
  // {
  // 	path: '/projects/:project/:bank',
  // 	element: (
  // 		<RequireAuth fallbackPath='/'>
  // 			<Bank />
  // 		</RequireAuth>
  // 	),
  // },
  // { path: "/forgot-password", element: <ForgotPassword /> },
  {
    path: "/sold-apartments",
    element: (
      <RequireAuth fallbackPath="/">
        <SoldApartments />
      </RequireAuth>
    ),
  },
  {
    path: "/change-password",
    element: (
      <RequireAuth fallbackPath="/">
        <ChangePassword />
      </RequireAuth>
    ),
  },
  {
    path: "/projects/:id",
    element: (
      <RequireAuth fallbackPath="/">
        <PrivateBuildings />
      </RequireAuth>
    ),
  },
  {
    path: "/sold-apartments/:project",
    element: (
      <RequireAuth fallbackPath="/">
        <Apartment />
      </RequireAuth>
    ),
  },
  {
    path: "/statistics/:project",
    element: (
      <RequireAuth fallbackPath="/">
        <ProjectStatistics />
      </RequireAuth>
    ),
  },
  // {
  //   path: "/sold-apartments/pending-fees",
  //   element: (
  //     <RequireAuth fallbackPath="/">
  //       <PendingFees />
  //     </RequireAuth>
  //   ),
  // },
  // {
  //   path: "/statistics/:project/extended",
  //   element: (
  //     <RequireAuth fallbackPath="/">
  //       <ExtendedGraphic />
  //     </RequireAuth>
  //   ),
  // },
  {
    path: "/late-bookings/:project",
    element: (
      <RequireAuth fallbackPath="/">
        <LateBookingsProjectInfo />
      </RequireAuth>
    ),
  },
  {
    path: "/projects/:project/subsidary/:building/:floorId",
    element: (
      <RequireAuth fallbackPath="/">
        <Building />
      </RequireAuth>
    ),
  },
  {
    path: "/sold-apartments/:apartment/:project",
    element: (
      <RequireAuth fallbackPath="/">
        <SoldApartmentsInfo />
      </RequireAuth>
    ),
  },
];

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    children: urls.map(({ path, element }) => ({ path, element })),
  },
]);
