import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdArrowDropdown } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

const languages = [
	{ lang: 'Հայերեն', short: 'Հայ', value: 'hy' },
	{ lang: 'English', short: 'Eng', value: 'en' },
	{ lang: 'Русский', short: 'Рус', value: 'ru' },
	{ lang: 'Français', short: 'Fr', value: 'fr' },
	{ lang: 'Español', short: 'Esp', value: 'es' },
];

const LanguageSelect = () => {
	const location = useLocation();
	const { i18n } = useTranslation();
	const [selectedLanguage, setSelectedLanguage] = useState<string>(
		localStorage.getItem('langShort') || 'Eng'
	);

	const handleClickLanguage = (value: string, short: string) => {
		i18n.changeLanguage(value);
		setSelectedLanguage(short);

		localStorage.setItem('i18nextLng', value);
		localStorage.setItem('langShort', short);
	};

	return (
		<div className='relative'>
			<Menu>
				<MenuButton>
					<div
						className={`w-[70px] h-[32px] border-2 border-${
							location.pathname === '/' ? 'white' : '#E8E8E8'
						} rounded-full flex items-center justify-between px-[10px] cursor-pointer`}
					>
						<span
							className={`text-${
								location.pathname === '/' ? 'white' : 'black'
							} font-bold`}
						>
							{selectedLanguage}
						</span>
						<IoMdArrowDropdown
							className={`${
								location.pathname === '/' ? 'text-white' : 'text-black'
							}`}
						/>
					</div>
				</MenuButton>
				<MenuList>
					{languages.map(language => (
						<div
							key={nanoid()}
							onClick={() =>
								handleClickLanguage(language.value, language.short)
							}
							className='w-full h-full py-1 flex items-center justify-center cursor-pointer transition hover:bg-slate-100'
						>
							{language.lang}
						</div>
					))}
				</MenuList>
			</Menu>
		</div>
	);
};

export default LanguageSelect;
