import { createStandaloneToast } from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";
import i18n from "../i18n";

const { toast } = createStandaloneToast();

export const $api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

$api.interceptors.request.use((config) => {
  const token = Cookies.get("_auth");

  if (token) {
    config.headers["x-auth-token"] = token;
  }

  return config;
});

$api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && Cookies.get("_auth")) {
      Cookies.remove("_auth");
      window.location.href = "/";
    }

    if (error.response.status === 403) {
      console.log("403");
      toast({
        title: i18n.t("error"),
        description: i18n.t("accessDenied"),
        status: "error",
        duration: 5000,
        position: "top-right",
        isClosable: true,
      });
    }
    return error;
  }
);
