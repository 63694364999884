import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  components: {
    Button: {
      variants: {
        custom: {
          backgroundColor: "#0B57D0",
          rounded: "full",
          color: "white",
          _hover: {
            backgroundColor: "#003ED0",
          },
          _active: {
            backgroundColor: "#0B57D0",
            transform: "translateY(3px)",
          },
          _disabled: {
            pointerEvents: "none",
          },
          _loading: {
            pointerEvents: "none",
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          height: "fit-content",
          size: "md",
          overflowY: "scroll",
        },
      },
    },
  },
});
