import { $api } from "./api-client";

export interface Reports {
  reports: Report[]
}

export interface Report {
  apartment: Apartment
  building: Building
  client: Client
  deal: Deal
  floor: Floor
  last_change_at: string
  last_change_by: LastChangeBy
  project_name: string
}

export interface Apartment {
  apartment_status: ApartmentStatus
  apartment_type: ApartmentType
  area: number
  base_price: number
  comment: string
  coordinates: string[]
  created_at: string
  floor_id: number
  id: number
  number: number
  renovated: boolean
  rooms: number
  title: string
}

export interface ApartmentStatus {
  id: number
  title: string
}

export interface ApartmentType {
  id: number
  title: string
}

export interface Building {
  address: string
  created_at: string
  id: number
  north_degree: number
  number: number
  project_id: number
  title: string
}

export interface Client {
  comment: string
  created_at: string
  email: string
  fullname: string
  id: number
  passport: string
  phone: string
  updated_at: string
}

export interface Deal {
  apartment_id: number
  bank_id: number
  client_id: number
  contract: boolean
  created_at: string
  deal_base_price: number
  deal_creator_id: number
  deal_end_date: string
  deal_responsible_id: number
  deal_start_date: string
  deal_status_id: number
  id: number
  payment_type_id: number
  prepayment: number
  updated_at: string
}

export interface Floor {
  building_id: number
  created_at: string
  id: number
  image_id: number
  number: number
  prefix: string
  title: string
}

export interface LastChangeBy {
  first_name: string
  last_name: string
  role_id: number
  username: string
}

export interface Filter {
    projectID: number
    buildingID: number
    floorID: number
    apartmentID: number
    status: number
    responsible: number
    date_from_enabled: boolean
    date_from: string
    date_to_enabled: boolean
    date_to: string
}

export interface ReportCreateResponse {
  uuid: string
}

export const getReportsByProjectID = async (id: string): Promise<Reports> => {
  return (await $api.get<Reports>(`/private/projects/${id}/reports`)).data;
};

export const getReportsByFilter = async (filter: Filter): Promise<ReportCreateResponse> => {
  return (await $api.post<ReportCreateResponse>(`/private/deals/report`, filter)).data;
};

export const getReportsByID = async (id: string): Promise<Reports> => {
  return (await $api.get<Reports>(`/private/deals/report/${id}`)).data;
};
