import { IApartment, IApartmentTypes, ICreateApartment } from "../@types/types";
import { $api } from "./api-client";

export interface IUpdateApartment {
  number: number;
  area: number;
  base_price: number;
}

export const getApartment = async (id: number): Promise<IApartment> => {
  return (await $api.get<IApartment>(`/private/apartments/${id}`)).data;
};

export const getApartmentTypes = async (): Promise<IApartmentTypes[]> => {
  return (await $api.get<IApartmentTypes[]>("/private/apartment_types")).data;
};

export const createOrUpdateApartment = async (
  id: number,
  data: ICreateApartment | IUpdateApartment
): Promise<{ success: boolean }> => {
  return (await $api.put<{ success: boolean }>(`/private/apartments/${id}`, data)).data;
};
