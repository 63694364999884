import { useDisclosure } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { API } from "../services/api";
import Drawer from "./Drawer";
import LanguageSelect from "./LanguageSelect";
import NameBar from "./NameBar";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  useQuery({
    queryKey: ["user"],
    queryFn: API.auth.authMe,
    enabled: location.pathname !== "/",
  });

  const isAuth = useIsAuthenticated();

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <header
      className={`w-full h-[60px] flex flex-col z-50 items-end justify-center fixed px-5 ${
        location.pathname !== "/" && "bg-white"
      } ${isMobile && "bg-white"}`}
    >
      <nav className="w-full flex items-center justify-between">
        {location.pathname === "/" && <div className="w-[50px] h-[20px]" />}
        {width > 499 ? (
          <img
            onClick={() => navigate("/projects")}
            src={`/images/${location.pathname === "/" ? "logo-white.svg" : "logo-blue.svg"}`}
            className="cursor-pointer"
            alt="white logo"
          />
        ) : (
          <img
            onClick={() => navigate("/projects")}
            src="/images/logo-blue.svg"
            className="cursor-pointer mr-10"
            alt="blue logo"
          />
        )}
        <div className="flex items-center gap-x-2 max-[620px]:hidden">
          <LanguageSelect />
          {isAuth && location.pathname !== "/" && <NameBar />}
        </div>

        {location.pathname === "/" && width <= 522 && <div className="w-[30px]" />}
        {location.pathname !== "/" && (
          <img onClick={onOpen} className="min-[620px]:hidden" src="/images/burger.svg" alt="burger-menu" />
        )}
      </nav>
      <Drawer isOpen={isOpen} onClose={onClose} />
    </header>
  );
};

export default Header;
