import { useTranslation } from "react-i18next";
import { useRouteError } from "react-router-dom";

const ErrorBoundary = () => {
  const error = useRouteError();
  const { t } = useTranslation();

  console.log(error);
  return (
    <div className="w-full h-screen bg-blue-100 flex items-center justify-center">
      <div className="flex flex-col items-center">
        <span className="text-red-500 text-3xl text-center">{t("somethingWentWrong")}</span>
        <span className="text-black text-md mt-5 text-center">{t("ifTheProblemExists")}</span>

        <span className="mt-3 text-gray-500 text-sm text-center">{t("ifYouAreTheDeveloper")} (F12)</span>
      </div>
    </div>
  );
};

export default ErrorBoundary;
