import { ICreateProject, IProjects, IProjectStatistics, Project } from "../@types/types";
import { $api } from "./api-client";

export const getAllProjects = async (): Promise<IProjects> => {
  return (await $api.get<IProjects>("/private/projects")).data;
};

export const getProjectById = async (id: number): Promise<Project> => {
  return (await $api.get<Project>(`/private/projects/${id}`)).data;
};

export const createNewProject = async (project: ICreateProject): Promise<Project> => {
  return (await $api.post<Project>("/private/projects", project)).data;
};

export const updateProject = async (id: number, project: ICreateProject): Promise<ICreateProject> => {
  return (await $api.put<ICreateProject>(`/private/projects/${id}`, project)).data;
};

export const deleteProject = async (id: number): Promise<void> => {
  await $api.delete(`/private/projects/${id}`);
};

export const getProjectStatistics = async (id: number): Promise<IProjectStatistics> => {
  return (await $api.get<IProjectStatistics>(`/private/projects/${id}/statistics`)).data;
};
