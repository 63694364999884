import {
  IChangePasswordInputs,
  ICreateUser,
  ICreateUserResponse,
  IRoles,
  IUpdateUser,
  IUser,
} from "../@types/types";
import { $api } from "./api-client";

export const getAllUsers = async (): Promise<IUser[]> => {
  return (await $api.get<IUser[]>("/private/users")).data;
};

export const getUserById = async (id: number): Promise<IUser> => {
  return (await $api.get<IUser>(`/private/users/${id}`)).data;
};

export const updateUser = async (id: number, data: IUpdateUser): Promise<{ success: boolean }> => {
  return (await $api.put<{ success: boolean }>(`/private/users/${id}`, data)).data;
};

export const createUser = async (data: ICreateUser): Promise<ICreateUserResponse> => {
  return (await $api.post<ICreateUserResponse>("/register", data)).data;
};

export const changeUserStatus = async (
  id: number,
  mode: "activate" | "deactivate"
): Promise<{ success: boolean }> => {
  return (await $api.put<{ success: boolean }>(`/private/users/${id}/${mode}`)).data;
};

export const getAllRoles = async (): Promise<IRoles[]> => {
  return (await $api.get<IRoles[]>("/private/users/roles")).data;
};

export const changeUserPassword = async (
  id: number,
  data: IChangePasswordInputs
): Promise<{ success: boolean }> => {
  return (await $api.put<{ success: boolean }>(`/private/users/${id}/change-password`, data)).data;
};
