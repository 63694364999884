import { ICreateFloor, IFloor, IFloors, INestedFloors } from "../@types/types";
import { $api } from "./api-client";

export interface FloorUpdateData {
  number: number;
}

export const getAllFloorsByBuildingId = async (buildingId: number): Promise<INestedFloors[]> => {
  return (await $api.get<IFloors>(`/private/floors/building/${buildingId}`)).data.floors;
};

export const createFloor = async (data: ICreateFloor): Promise<IFloor> => {
  return (await $api.post<IFloor>("/private/floors", data)).data;
};

export const deleteFloor = async (id: number): Promise<{ success: boolean }> => {
  return (await $api.delete<{ success: boolean }>(`/private/floors/${id}`)).data;
};

export const updateFloor = async (id: number, data: FloorUpdateData): Promise<{ success: boolean }> => {
  return (await $api.put<{ success: boolean }>(`/private/floors/${id}`, data)).data;
};

export const copyFloor = async (id: number): Promise<{ id: number }> => {
  return (await $api.post<{ id: number }>(`/private/floors/copy/${id}`)).data;
};
