import { IAuthMe } from "../@types/types";
import { $api } from "./api-client";

export interface LoginInputs {
  username: string;
  password: string;
  time_zone: string;
}

export interface ILoginResponse {
  first_name: string;
  id: number;
  last_name: string;
  role_id: number;
  token: string;
  username: string;
}

export const login = async (inputs: LoginInputs): Promise<ILoginResponse> => {
  return (await $api.post<ILoginResponse>("/login", inputs)).data;
};

export const authMe = async (): Promise<IAuthMe> => {
  return (await $api.get<IAuthMe>("/auth/me")).data;
};
