import {
  Drawer as ChakraDrawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import LanguageSelect from "./LanguageSelect";
import NameBar from "./NameBar";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const Drawer = (props: Props) => {
  return (
    <ChakraDrawer isOpen={props.isOpen} placement="right" onClose={props.onClose} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <div className="mt-10" />
        <DrawerHeader className="flex justify-end">
          <NameBar onClose={props.onClose} />
        </DrawerHeader>

        <DrawerFooter>
          <LanguageSelect />
        </DrawerFooter>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export default Drawer;
