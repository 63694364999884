import * as auth from "./auth";
import * as buildings from "./buildings";
import * as images from "./images";
import * as projects from "./projects";
import * as floors from "./floors";
import * as statuses from "./statuses";
import * as apartment from "./apartments";
import * as paymentTypes from "./paymentTypes";
import * as users from "./users";
import * as late_bookings from './late-bookings'
import * as sold_apartments from './sold-apartments'
import * as reports from './reports'

export const API = {
  auth,
  projects,
  late_bookings,
  sold_apartments,
  reports,
  images,
  buildings,
  floors,
  statuses,
  apartment,
  paymentTypes,
  users
};
